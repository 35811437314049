html,body{
   height: 100%;
}

html {
   font-size: 62.5%;
}
body {
   font-size: 1.6rem;
   margin: 0;
   font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
      'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
      'Helvetica Neue', sans-serif;
   -webkit-font-smoothing: antialiased;
   -moz-osx-font-smoothing: grayscale;
}

p {
   font-size: 1.6rem;
}

a:hover, button:hover{
   cursor: pointer;
}
